<template>
    <div>
        <div class="jumbotron jumbotron-fluid title__secondary">
            <div class="container max-width--1000px">
                <h1 class="display-4 title-center">Over Checkjelinkje<sup><small>®</small></sup></h1>
            </div>
        </div>
        <div class="bg--white">
            <div class="container">

                <div class="space--50"></div>

                <div class="row">
                    <div class="col-md-7">
                        <h3>Wat is Checkjelinkje?</h3>
                        <br>
                        <p>
                            <strong>
                                Checkjelinkje<sup><small>®</small></sup> is een handig hulpmiddel om (verkorte) links en websites op veiligheid en
                                betrouwbaarheid te controleren. Omdat je sommige links beter links kunt laten liggen.
                            </strong>
                        </p>
                        <p>
                            <i>‘Leidt dat linkje in die mail of op die site wel echt naar mijn bank of mijn verzekeraar?’</i>
                        </p>
                        <p>
                            Maar Checkjelinkje is meer dan dat. In korte tijd is het uitgegroeid tot een autoriteit op het gebied
                            van online veiligheid. Al zeggen we het zelf. Kijk maar even mee:
                        </p>
                        <ul>
                            <li>We hebben een online linkchecker waarmee je (verdachte) links vlot kunt checken op
                                betrouwbaarheid.</li>
                            <li>We bieden je ook een handige extensie voor Chrome en Firefox waarmee je websites en links
                                automatisch in je browser kunt checken, ook als je al op de link hebt geklikt.</li>
                            <li>We helpen consumenten en bedrijven met tips, tools en trainingen om het bewustzijn van
                                cyberveiligheid te vergroten.</li>
                        </ul>
                        <p>Onze plannen voor de toekomst zijn nog veel ambitieuzer. En jij kunt helpen om ons platform nóg
                            beter te maken.</p>
                        <br>
                       <h3>
                           Het gaat om jouw online veiligheid
                       </h3>
                        <p>
                            Checkjelinkje blijkt keer op keer weer geen overbodige luxe. We krijgen elke dag te maken met
                            een onophoudelijke stroom phishing-mails en malware-websites.
                        </p>
                        <p>Een ongelukkig klikje zit in een klein hoekje. Iedereen is druk en wil zo snel mogelijk verder en
                            daar maken kwaadwillenden helaas misbruik van.</p>
                        <p>
                            Daarom is Checkjelinkje een onmisbare service voor:
                        </p>
                        <p>
                            Consumenten die niet het slachtoffer van phishing en cybercriminaliteit willen worden. <br>
                            Alle organisaties en bedrijven die de online veiligheid van hun klanten als topprioriteit hebben.
                        </p>
                        <p>
                            Checkjelinkje wordt onder andere aanbevolen door <strong>de Consumentenbond</strong>, door <strong>Veilig
                            Internetten</strong> en <strong>het ministerie van Justitie en Veiligheid</strong>.
                        </p>
                        <p>
                            Wij werken doorlopend aan updates om nieuwe malware en phishing op te sporen, zonder dat je
                            er omkijken naar hebt. Verdenk je zelf een website van phishing? Geef deze dan eenvoudig aan
                            ons door nadat je de check hebt uitgevoerd, dan duiken wij er meteen in.
                        </p>
                        <br>
                        <h3>
                            Privacy first!
                        </h3>
                        <p>
                            Hoe zit het met privacy? Heel simpel. We slaan alleen gegevens op die we nodig hebben om de
                            tool te laten werken. Gegevens die ook maar op de een of andere manier gevoelig kunnen zijn,
                            verwijderen we automatisch binnen 2 weken van onze server.
                        </p>
                        <p>
                            Ook al is het gebruik van onze dienst gratis, als gebruiker ben je hier nooit het product. We kunnen
                            deze dienst kosteloos aanbieden doordat we voor bedrijven zakelijke oplossingen tegen
                            cybercriminaliteit bieden.
                        </p>
                        <p>
                            Juist omdat we zelf voorop willen blijven lopen met online veiligheid en privacy, nemen we jouw
                            privacy bijzonder serieus.
                        </p>
                        <p>
                            Wil je het naadje van de privacykous weten? Lees dan ons <router-link to="/juridisch">privacybeleid</router-link> en <router-link to="/juridisch">de bijbehorende
                            voorwaarden</router-link>.
                        </p>
                        <br>
                        <h3>
                            Cyberveiligheid makkelijk gemaakt
                        </h3>
                        <p>
                            Veel mensen vinden cyberveiligheid maar moeilijk en complex. Want wat kun je precies doen en is
                            dat niet allemaal erg omslachtig? Ze nemen pas maatregelen als ze zijn gehackt of slachtoffer zijn
                            geworden van phishing.
                        </p>
                        <p>
                            Daarom maken wij het graag makkelijk. Voor consumenten én voor bedrijven die net als wij het
                            voortouw willen nemen als het op veiligheid aankomt.
                        </p>
                        <br>
                        <h4>Contact met Checkjelinkje</h4>
                        <p>
                            We kunnen je helaas niet 1-op-1 helpen met links die je hebt ontvangen. Daarvoor kun je
                            <router-link to="/">onze linkchecker gebruiken</router-link>.
                        </p>
                        <p>
                            Wil je contact met ons opnemen over iets anders? Dan kun je ons bereiken via e-mail. Ons e-mailadres is
                            <a href="mailto:support@checkjelinkje.nl">support@checkjelinkje.nl</a>.
                        </p>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-4">

                        <div class="position-sticky">
                            <h3 class="title">Download de extensie</h3>
                            <p>
                                Linkjes checken zonder handmatig onze website te bezoeken? Gebruik onze handige
                                browserextensies.
                                <br><br>
                                <a href="https://chrome.google.com/webstore/detail/checkjelinkje-voor-je-bro/emhmcmaagaihdokaiccmmidnfndeilil" target="_blank">Download voor <i
                                        class="fab fa-chrome"></i> Chrome</a><br>
                                <a href="https://addons.mozilla.org/nl/firefox/addon/checkjelinkje-nl-in-je-browser/" target="_blank">Download voor <i
                                        class="fab fa-firefox"></i> Firefox</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <br><br><br>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'About'
    }
</script>
